import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout"; 
import SEO from "../components/SEO";
import BodyContentRender from "../components/BodyContentRender";

export const AboutPageTemplate = ({ title, content }) => {
 

  return (
    <section className="section">
      <div className="w-full px-4 md:px-6 py-6 container mx-auto">
        <BodyContentRender markdown={content} />
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.metatitle}
        description={post.frontmatter.metadescription}
      />
      <AboutPageTemplate
 
        title={post.frontmatter.title}
        content={post.frontmatter.bodycontent}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        metatitle
        slug
        metadescription
        metakeywords
        bodycontent
      }
    }
  }
`;
